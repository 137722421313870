export function bufCompressToUTF16(buffer) {
  const s = new Uint8Array(buffer);
  let out = '';
  const sl1 = s.byteLength - 1;

  for (let i = 0, l = s.byteLength; i < l; i += 2) {
    let x = s[i];
    let y = 0;

    if (i < sl1) {
      y = s[i + 1];
    }

    // apply an offset to the first byte in a pair to minimize use of the zero page where there
    // are several forbidden codes.
    x += 1;

    const code = ((x << 8) + y) & 0xffff;
    // need to truncate calculation as the range of x is 1..256, and 256 needs to map to 0 because of offset

    if (
      code < 32 ||
      (code >= 127 && code < 160) ||
      code === 39 ||
      code === 92 ||
      code === 173 ||
      (code >= 0xd800 && code <= 0xdfff) ||
      code === 0xfffe ||
      code === 0xffff ||
      (code >= 0xfdd0 && code <= 0xfdef) ||
      code === 0x2028 ||
      code === 0x2029 ||
      (code & 0xff00) === 0xd600
    ) {
      // need to output two chars as the code is a forbidden utf16 value.
      out += String.fromCharCode(0xd600 + (x - 1)); // lo byte is unshifted in this case.
      out += String.fromCharCode(0xd600 + y);
    } else {
      out += String.fromCharCode(code);
    }
  }

  // Add a snowman prefix to mark the resulting string as encoded.
  return String.fromCharCode(9732) + out;
}

export function bufDecompressFromUTF16(s, len = (s.length - 1) * 2) {
  // If not prefixed with a summat, just return the (already uncompressed) string.
  if (s.charCodeAt(0) !== 9732) {
    return s;
  }

  const buffer = new ArrayBuffer(len);
  const buf = new Uint8Array(buffer);
  let cur = 0;
  let i = 1;
  while (i < s.length) {
    const n = s.charCodeAt(i++);
    const m = ((n >> 8) - 1) & 255;

    if (m !== 0xd5) {
      // the marker for forbidden utf16 codes in compressor minus the offset avoiding zero page.
      buf[cur++] = m;
    }
    buf[cur++] = n & 255;
  }

  return buffer;
}
