import Vue from 'vue';
import alertSquare from './graphics/alert-square.svg';
import alert from './graphics/alert.svg';
import checkbox from './graphics/checkbox.svg';
import infoIcon from './graphics/help.svg';
import homeIcon from './graphics/home.svg';
import fileDownload from './graphics/ic_file_download_black_24px.svg';
import questionIcon from './graphics/question.svg';
import radio from './graphics/radio.svg';
import speech from './graphics/speech.svg';
import tickSquare from './graphics/tick-square.svg';
import bars from './graphics0/bars.svg';
import buttonTick from './graphics0/button-tick.svg';
import crossRound from './graphics0/cross-round.svg';
import nextPatient from './graphics0/next-patient.svg';
import nextTester from './graphics0/next-tester.svg';
import prevPatient from './graphics0/prev-patient.svg';
import prevTester from './graphics0/prev-tester.svg';
import tickRound from './graphics0/tick-round.svg';

export const graphicsTemplates = {
  init() {
    Vue.component('next-tester', { template: nextTester });
    Vue.component('prev-tester', { template: prevTester });
    Vue.component('next-patient', { template: nextPatient });
    Vue.component('prev-patient', { template: prevPatient });
    Vue.component('bars', { template: bars });
    Vue.component('tick-round', { template: tickRound });
    Vue.component('cross-round', { template: crossRound });
    Vue.component('button-tick', { template: buttonTick });
    Vue.component('home-icon', { template: homeIcon });
    Vue.component('file-download', { template: fileDownload });
    Vue.component('checkbox', { template: checkbox });
    Vue.component('alert', { template: alert });
    Vue.component('radio-icon', { template: radio });
    Vue.component('speech-icon', { template: speech });
    Vue.component('tick-square', { template: tickSquare });
    Vue.component('alert-square', { template: alertSquare });
    Vue.component('question-icon', { template: questionIcon });
    Vue.component('info-icon', { template: infoIcon });
  },
};
