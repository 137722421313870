import { DataManager } from 'onc';
import version from '../version.js';
import { dbStore } from './dbStore.js';

function checkMediaVersion(stores, needDelete) {
  return stores.media
    .getItem('v')
    .then(v => {
      if (!v) {
        return stores.media.setItem('v', version);
      }
    })
    .catch(e => alert(e.toString()));
}

function initializeDatabase() {
  return dbStore
    .init('ocsbridge2')
    .then(() => checkMediaVersion({ media: dbStore.media }))
    .then(() => DataManager.init('', `/api/data`, { db: dbStore.queue }));
}

export const dbInit = () => initializeDatabase();
