import { RBAC } from 'onc';

export default {
  namespaced: true,
  state: {
    currentRbac: null,
  },
  mutations: {
    SET_CURRENT_RBAC(state, newValue) {
      state.currentRbac = newValue;
    },
  },
  getters: {
    can: (state, getters, rootState, rootGetters) => (operation, params) => {
      const myRole = rootGetters['auth/myRole'];
      return state.currentRbac.can && myRole && state.currentRbac.canSync(myRole, operation, params);
    },
  },
  actions: {
    // // This is automatically run in `src/state/store.js` when the app
    // // starts, along with any other actions named `init` in other modules.
    // init({ state, dispatch }) {
    //   //  const rbac = Object.create(RBAC).init(roles);
    // },

    perms({ commit }, { roles }) {
      const rbac = Object.create(RBAC).init(roles);
      commit('SET_CURRENT_RBAC', rbac);
    },
  },
};
