// import 'hand';
import * as snackbar from '@mcwv/snackbar/index';
import router from '@router/index.js';
import store from '@state/store.js';
import { shuffle, storage } from 'onc';
import seedrandom from 'seedrandom';
import Vue from 'vue';
import app from './app.js';
import { buttonRadio } from './components/button-radio.js';
import { vFast } from './components/directives.js';
import { graphicsTemplates } from './components/graphicsTemplates.js';
import { mainMenu } from './components/main-menu.js';
import { vNobounce, vScroll, vVisible } from './components/plugins.js';
import { questionDialog } from './components/question-dialog.js';
import { dbInit } from './db/dbInit.js';
import vueerr from './error.js';
import loader from './loader.js';

const width = 256;
const pool = [];

export default function main() {
  captureContextMenu();

  checkIOS();

  Vue.use(vueerr);
  Vue.use(snackbar['default']);
  Vue.component('oncSnackbar', () => loader.load('components/onc-snackbar.js'));

  storage.setPrefix('ocsbridge:');
  shuffle.setRng(seedrandom());

  const rng = seedrandom(autoseed());
  shuffle.setRng(rng);

  graphicsTemplates.init();

  Vue.use(vVisible);
  Vue.use(vNobounce);
  Vue.use(vScroll);
  Vue.directive('fastclick', vFast);

  Vue.component('main-menu', mainMenu);
  Vue.component('button-radio', buttonRadio);
  Vue.component('question-dialog', questionDialog);

  dbInit().then(() => {
    new Vue({
      router,
      store,
      render: h => h(app),
      computed: {
        noRipple() {
          return true;
        },
      },
    }).$mount('#app');
  });
}

main();

// private functions

function captureContextMenu() {
  window.addEventListener('contextmenu', e => {
    e.returnValue = false;
    e.preventDefault();
  });
}

function checkIOS() {
  const iosVersion = navigator.userAgent.match(/OS (\d+)_(\d+)/i);

  // we want to suppress active/hover on iPad as it is persistent across page loads in a web app.
  const isIpad = /ipad/i.test(navigator.userAgent);
  if (isIpad) {
    // using classList is safe here as iOS is known to support it.
    document.querySelector('html').classList.add('ipad');
  }

  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    if (iosVersion[1] >= 9) {
      document
        .querySelector('meta[name=viewport]')
        .setAttribute(
          'content',
          'initial-scale=1.0001, minimum-scale=1.0001, maximum-scale=1.0001, user-scalable=no viewport-fit=cover',
        );
    }
  }
}

function tostring(a) {
  return String.fromCharCode.apply(0, a);
}

function autoseed() {
  try {
    const out = new Uint8Array(width);
    (global.crypto || global.msCrypto).getRandomValues(out);
    return tostring(out);
  } catch (e) {
    const browser = global.navigator;
    const plugins = browser && browser.plugins;
    return [+new Date(), global, plugins, global.screen, tostring(pool)];
  }
}
