const HANDLERCLICK = '_v-fastclick-click';
const HANDLERVALUE = '_v-fastclick-value';
const HANDLERDOWN = '_v-fastclick-down';
const HANDLERUP = '_v-fastclick-up';
const HANDLERMOVE = '_v-fastclick-move';

// simple test for ipad
const isIpad = navigator.standalone && /ipad/i.test(navigator.userAgent);
const isIphone = navigator.standalone && /iPhone/i.test(navigator.userAgent);
const ieTouch = navigator.standalone && /trident.+touch/i.test(navigator.userAgent);

const fastclickutil = {
  setup(el, vnode) {
    this.trackingClick = false;
    this.trackingClickStart = 0;
    this.x = 0;
    this.y = 0;
    this.tolerance = 10;
    this.clickTimeout = 700;
    this.el = el;
    this.context = vnode.context;

    return this;
  },
  hasMoved(e) {
    const tolerance = this.tolerance;
    const evt = e.changedTouches[0];
    if (Math.abs(evt.x - this.x) > tolerance || Math.abs(evt.y - this.y) > tolerance) {
      return true;
    }
    return false;
  },
  touchend(e) {
    if (!this.trackingClick) {
      return;
    }

    if (e.timeStamp - this.trackingClickStart > this.clickTimeout) {
      return;
    }

    this.trackingClick = false;
    this.trackingClickStart = 0;

    const callback = this.el[HANDLERVALUE];

    e.preventDefault();

    if (typeof callback === 'function') {
      return callback.call(this.context, e);
    }

    return callback.handler.call(this.context, callback.arg);
  },
  touchmove(e) {
    if (!this.trackingClick) {
      return;
    }

    if (this.hasMoved(e)) {
      this.trackingClick = false;
    }

    return;
  },
  touchstart(e) {
    this.trackingClick = true;
    this.trackingClickStart = e.timeStamp;
    const evt = e.changedTouches[0];
    this.x = evt.x;
    this.y = evt.y;

    return;
  },
};

function bind(el, binding, vnode) {
  unbind(el);

  if (isIpad || isIphone || ieTouch) {
    el[HANDLERVALUE] = binding.value;
    const util = Object.create(fastclickutil).setup(el, vnode);

    el[HANDLERDOWN] = util.touchstart.bind(util);
    el[HANDLERUP] = util.touchend.bind(util);
    el[HANDLERMOVE] = util.touchmove.bind(util);

    el.addEventListener('touchstart', el[HANDLERDOWN], false);
    el.addEventListener('touchend', el[HANDLERUP], false);
    el.addEventListener('touchmove', el[HANDLERMOVE], false);
  } else {
    el[HANDLERVALUE] = binding.value;
    el[HANDLERCLICK] = function(ev) {
      const callback = el[HANDLERVALUE];

      ev.preventDefault();
      if (typeof callback === 'function') {
        return callback.call(vnode.context, ev);
      }
      return callback.handler.call(vnode.context, callback.arg);
    };
    el.addEventListener('click', el[HANDLERCLICK], false);
  }
}

function update(el, binding, vnode) {
  el[HANDLERVALUE] = binding.value;
}

function unbind(el) {
  if (isIpad || isIphone || ieTouch) {
    el.removeEventListener('touchstart', el[HANDLERDOWN], false);
    el.removeEventListener('touchend', el[HANDLERUP], false);
    el.removeEventListener('touchmove', el[HANDLERMOVE], false);

    delete el[HANDLERDOWN];
    delete el[HANDLERUP];
    delete el[HANDLERMOVE];
  } else {
    {
      el.removeEventListener('click', el[HANDLERCLICK], false);
      delete el[HANDLERCLICK];
    }
  }
}

export const vFast = {
  bind,
  update,
  unbind,
};
