import template from './main-menu.html';

export const mainMenu = {
  template,
  props: ['items'],
  data() {
    return { isVisible: false };
  },
  methods: {
    toggle() {
      this.isVisible = !this.isVisible;
    },
    onMenuClick(item) {
      this.isVisible = false;
      item.onselect && item.onselect();
    },
  },
};
