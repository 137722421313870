import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import modules from './modules/index.js';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['route'],
});

const store = new Vuex.Store({
  strict: true, // This makes the Vuex store strict
  state: {
    isOpen: 0,
    snackbarHandler: null,
    dateVerified: false,
  },
  mutations: {
    open: function(state) {
      state.isOpen = true;
    },
    close: function(state) {
      state.isOpen = false;
    },
    setSnackbarHandler(state, handler) {
      state.snackbarHandler = handler;
    },
    setDateVerified: function(state, newValue = true) {
      state.dateVerified = newValue;
    },
  },
  plugins: [vuexLocal.plugin],
  actions: {
    showSnack({ state }, snack) {
      if (state.snackbarHandler) {
        state.snackbarHandler(snack);
      }
    },
  },
  modules,
});

// Automatically run the `init` action for every module,
// if one exists.

Object.keys(modules).reduce(async (p, moduleName) => {
  await p;
  if (modules[moduleName].actions.init) {
    p = store.dispatch(`${moduleName}/init`);
  }

  return p;
}, Promise.resolve());
export default store;
