import template from './button-radio.html';

export const buttonRadio = {
  template,
  props: {
    value: String,
    radioValue: String,
    disabled: { type: Boolean, default: false },
  },
  methods: {
    onClick({ target }) {
      if (!this.disabled) {
        target && target.blur();
        if (this.radioValue !== this.value) {
          this.$emit('input', this.radioValue);
        }
      }
    },
  },
  computed: {
    isActive() {
      return this.value === this.radioValue;
    },
  },
};
