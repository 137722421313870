/* eslint-disable prefer-promise-reject-errors */
import lf from 'localforage';
import { storage } from 'onc';
import SQLStorage from './web-sql-driver.js';
const stores = {};

const maxTries_ = 3;
const triesKey_ = 'db-restart-tries';
const deleteKey_ = 'db-delete';

const extmap = {
  '.png': 'image/png',
  '.jpg': 'image/jpg',
  '.svg': 'image/svg+xml',
  '.bmp': 'image/bmp',
  '.tif': 'image/tiff',
  '.mp4': 'video/mp4',
};

function guessMediaType(key) {
  const match = key.match(/\.[a-zA-Z0-9]+$/) || ['.png'];
  return extmap[match[0].toLowerCase()] || 'image/png';
}

function dropTables(name) {
  return new Promise((resolve, reject) => {
    const db = openDatabase(name, '1', '', 4980736);
    db.transaction(
      'DROP TABLE media',
      [],
      function(t, results) {
        resolve();
      },
      function(t, error) {
        reject();
      },
    );
  });
}

function restart() {
  const tries = storage.getItem(triesKey_) || 0;

  if (tries < maxTries_) {
    storage.setItem(triesKey_, tries + 1);
    return true;
  }
}

function deletedb(name) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.deleteDatabase(name);
    request.onsuccess = () => {
      alert('deleted');
      storage.removeItem('dbdelete');
      storage.removeItem('assetcheck');
      resolve();
    };
    request.onerror = event => {
      alert(event.toString());
      console.dir(event);
      reject();
    };
  });
}

function checkQueue(name) {
  return stores.queue
    .setItem('queue-test', { p: 'dbStore-test' })
    .then(() => {
      return stores.queue.removeItem('queue-test');
    })
    .catch(e => {
      const driver = stores.queue.driver();
      if (driver === lf.INDEXEDDB) {
        return restart();
      }

      return dropTables(name).then(restart);
    });
}

export const dbStore = {
  init(name = 'dbStore') {
    const needDelete = storage.getItem(deleteKey_);
    if (needDelete) {
      return deletedb(name)
        .then(restart)
        .then(restartRequested => {
          if (restartRequested) {
            location.reload();
          } else {
            storage.removeItem(deleteKey_);
          }
        });
    }

    return (
      lf
        .defineDriver(SQLStorage)
        // return Promise.resolve()
        .then(() => {
          stores.media = lf.createInstance({
            name,
            storeName: 'media',
            driver: [lf.INDEXEDDB, 'SQLStorage'],
            size: 4980736,
          });

          stores.sessions = lf.createInstance({ name, storeName: 'sessions' });
          stores.queue = lf.createInstance({ name, storeName: 'queue' });
        })
        .then(() => checkQueue(name))
        .then(restartRequested => {
          if (restartRequested) {
            location.reload();
          } else {
            storage.removeItem(triesKey_);
            storage.removeItem(deleteKey_);
          }
        })
    );
  },

  get media() {
    return stores.media;
  },
  get sessions() {
    return stores.sessions;
  },
  get queue() {
    return stores.queue;
  },

  toBlob(key, value) {
    const type = guessMediaType(key);
    return new Blob([value], { type });
  },
};
