import Vue from 'vue';

function preventDefault(event) {
  if (!event._isScroller) {
    event.preventDefault();
  }
}

export const vNobounce = {
  bind(el, binding) {
    el.removeEventListener('touchmove', preventDefault);
    el.addEventListener('touchmove', preventDefault);
  },
  unbind(el, binding) {
    el.removeEventListener('touchmove', preventDefault);
  },
};

const vNobouncePlugin = {
  install(vue, options) {
    Vue.directive('nobounce', vNobounce);
  },
};

export default vNobouncePlugin;
