/* eslint quote-props: 0 */
function actionHandler() {
  window.postMessage('sjs-reload', location.href);
}

export default {
  render(h) {
    return h('div', { attrs: { id: 'app' }, class: { main: true } }, [
      h('router-view', { attrs: { key: this.$route.fullPath } }, {}),
      h(
        'onc-snackbar',
        {
          ref: 'snackbar',
        },
        {},
      ),
    ]);
  },
  created() {
    window.addEventListener('message', this.receiveMessage, false);
  },
  mounted() {
    this.$store.commit('setSnackbarHandler', this.onSnack);
  },
  methods: {
    onSnack(snack) {
      const snackbar = this.$refs.snackbar;
      if (snackbar) {
        snackbar.handleSnack(snack);
      }
    },
    receiveMessage(event) {
      if (event.data === 'sjs-update') {
        this.$store.dispatch('showSnack', {
          labelText: 'A new version is available.',
          actionText: 'Reload',
          dismissAction: true,
          actionHandler,
        });
      }
    },
  },
};
