import store from '@state/store.js';
import loader from '../loader.js';
export const routes = [
  {
    path: '/client/session/:id',
    meta: { authRequired: true },
    component: () => loader.load('clientSession/client-session.js'),
    props: true,
    children: [
      {
        path: '',
        name: 'session',
        component: () => loader.load('clientSession/session-steps.js'),
      },
      {
        path: 'clientDetails',
        name: 'clientDetails',
        component: () => loader.load('clientSession/client-details/client-details.js'),
      },
      {
        path: 'assessmentDetails',
        name: 'assessmentDetails',
        component: () => loader.load('clientSession/assessment-details.js'),
      },
      {
        path: 'environment',
        name: 'environment',
        component: () => loader.load('clientSession/test-environment.js'),
      },
      {
        path: 'pretest',
        name: 'pretest',
        component: () => loader.load('clientSession/pretest.js'),
      },
      {
        path: 'notes',
        name: 'notes',
        component: () => loader.load('clientSession/session-notes.js'),
      },
      {
        path: 'settings',
        name: 'sessionsettings',
        component: () => loader.load('clientSession/session-settings.js'),
      },
      {
        path: 'cities',
        name: 'cities',
        component: () => loader.load('settings/cities.js'),
      },
      {
        path: 'tests',

        component: () => loader.load('testselection/test-selection.js'),
        props: true,
        children: [
          {
            path: '',
            name: 'tests',
            component: () => loader.load('testselection/suite-list.js'),
          },
          {
            path: 'suite/:suiteId',

            component: () => loader.load('testselection/suites.js'),
            children: [
              {
                path: '',
                name: 'suite',
                component: () => loader.load('testselection/suite-view.js'),
              },
              {
                path: 'task/:taskId/:subTaskId?',
                name: 'suitetask',
                component: () => loader.load('testselection/test.js'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/client/session',
    name: 'sessionselection',
    meta: { authRequired: true },
    component: () => loader.load('clientSession/session-selection.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/sessions',
    name: 'sessionselection',
    meta: { authRequired: true },
    component: () => loader.load('clientSession/select-sessions.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/login',
    name: 'login',
    component: () => loader.load('login/login.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
    },
  },
  {
    path: '/client/home',
    name: 'home',
    meta: { authRequired: true },
    component: () => loader.load('home/home.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/mainhelp',
    name: 'mainhelp',
    component: () => loader.load('help/help.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/install',
    name: 'install',
    meta: { authRequired: true },
    component: () => loader.load('install/install.js'),
    props: true,
    children: [],
  },
  {
    path: '/client/settings/:id?/:location?',
    name: 'appsettings',
    component: () => loader.load('settings/settings.js'),
    props: true,
  },
  {
    path: '/client/cities/:location?/:sessionId?',
    component: () => loader.load('settings/cities.js'),
    props: true,
  },
  {
    path: '/client/start',
    name: 'start',
    component: () => loader.load('apphelper.js'),
    props: true,
    children: [],
  },
  { path: '/client/:remainder*', redirect: { name: 'start' } },
  { path: '/app/:remainder*', redirect: { name: 'start' } },
  // { path: '/client/client-ac.html', redirect: { name: 'start' } },
];
