import template from './question-dialog.html';

const _gap = 15;
const _magicTop = 94;
function preventDefault(event) {
  event.preventDefault();
}

export const questionDialog = {
  template,
  props: { isWide: Boolean, text: String, isInfo: Boolean },
  data() {
    return {
      isVisible: false,
      containerStyle: { top: '', left: '', width: '' },
      arrowStyle: { left: '' },
      arrowClass: '',
    };
  },

  methods: {
    resize(evt) {
      this.calculatePosition();
    },
    onHide(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isVisible = false;
      window.removeEventListener('resize', this.resize);
      document.body.classList.remove('question-dialog-scroll-lock');
      if (this.scroller) {
        this.scroller.removeEventListener('touchmove', preventDefault);
        this.scroller = null;
      }
    },
    onIgnore(event) {
      event.stopPropagation();
      event.preventDefault();
    },

    calculatePosition() {
      const bodyWidth = document.body.offsetWidth;
      // find the location on the scren of the icon
      const iRect = this.$refs.icon.getBoundingClientRect();
      const isRhs = iRect.left > bodyWidth / 2;
      this.arrowClass = isRhs ? 'help-arrow' : 'help-arrow right';

      // if using a wide box set the width now - must be before
      // potentially get the bounding rectangle below.
      if (this.isWide) {
        const maxWidth = isRhs ? iRect.left : bodyWidth - iRect.left - iRect.width;

        // allow a gap either side of the dialog
        this.containerStyle.width = `${maxWidth - 2 * _gap}px`;
      }
      this.$nextTick(() => {
        const container = this.$refs.container;
        const top = iRect.top - container.offsetHeight / 2 + iRect.height / 2;

        this.containerStyle.top = `${top}px`;
        this.arrowStyle.top = `${iRect.top + iRect.height / 2 - 10}px`;

        if (isRhs) {
          const cRect = container.getBoundingClientRect();
          this.containerStyle.left = `${iRect.left - cRect.width - 20}px`;
          this.arrowStyle.left = `${iRect.left - 20}px`;
        } else {
          const left = _gap + iRect.left + iRect.width + 10;
          this.containerStyle.left = `${left}px`;
          this.arrowStyle.left = `${iRect.left + iRect.width + 15}px`;
        }

        if (top < _magicTop) {
          this.containerStyle.top = `${_magicTop}px`;
        } else if (top + container.offsetHeight > document.body.offsetHeight) {
          this.containerStyle.top = `${document.body.offsetHeight - container.offsetHeight - 10}px`;
        }
      });
    },

    onClick(event) {
      event.stopPropagation();
      event.preventDefault();

      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        window.addEventListener('resize', this.resize);
        document.body.classList.add('question-dialog-scroll-lock');
        this.scroller = document.querySelector('.scroll-y');
        if (this.scroller) {
          this.scroller.addEventListener('touchmove', preventDefault);
        }

        this.calculatePosition();
      } else {
        document.body.classList.remove('question-dialog-scroll-lock');
      }
    },
  },
  beforeDestroy() {
    if (this.scroller) {
      this.scroller.removeEventListener('touchmove', preventDefault);
    }

    window.removeEventListener('resize', this.resize);
  },
};
