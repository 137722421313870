/* eslint quote-props: 0 */

const namespaced = true;
const ORIGIN = window.location.origin;
const MAX_PING_FAILURES = 3;
const PING_INTERVAL = 30 * 1000;
const MAXOFFSET = 30 * 1000;

let pingerInterval;

export default {
  namespaced,
  state: {
    pingerLastStatus: null,
    failedPings: 0,
    pingerCheckClock: false,
  },
  mutations: {
    setFailedPings(state, newValue) {
      state.failedPings = newValue;
    },
    setPingerLastStatus(state, newValue) {
      state.pingerLastStatus = newValue;
    },
    setPingerCheckClock(state, newValue) {
      state.pingerCheckClock = newValue;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, commit, dispatch, getters }) {
      startPinger(state, commit);
    },
  },
};

// ===
// Private functions
// ===

function startPinger(state, commit) {
  fetch(`${ORIGIN}/api/ping`, {
    headers: {
      Accept: 'application/json',
    },
  })
    .then(res => {
      if (!res.ok) {
        throw new Error(`Bad return status: ${res.status}`);
      }

      commit('setPingerLastStatus', 'online');
      commit('setFailedPings', 0);

      const dateStr = res.headers.get('date');
      const serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateStr)).toUTCString());
      const localMillisUTC = Date.parse(new Date().toUTCString());

      const offset = Math.abs(serverTimeMillisGMT - localMillisUTC);
      commit('setPingerCheckClock', offset > MAXOFFSET);
    })
    .catch(e => {
      console.error('Gateway unreachable:', e);
      commit('setFailedPings', state.failedPings + 1);
      if (state.failedPings >= MAX_PING_FAILURES) {
        commit('setPingerLastStatus', 'offline');
      }
    });

  if (!pingerInterval) {
    pingerInterval = setInterval(startPinger.bind(null, state, commit), PING_INTERVAL);
  }
}
